import axios from 'axios'
import qs from 'qs'
import Crud from '../utils/crud'
const crud = new Crud('/workings')

const state = () => ({
  ...crud.state
})
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  rotateDocumentStatus (context, data) {
    return new Promise((resolve, reject) => {
      axios.put('/rotate-document-status', data).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }
}
const mutations = {
  ...crud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
