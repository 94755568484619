<template>
  <div v-if="!isLoading" class="wrapper">
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <div class="main-panel" :data="sidebarBackground">
      <zoom-center-transition :duration="200" mode="out-in">
        <!-- your content here -->
        <router-view />
      </zoom-center-transition>
    </div>
  </div>
</template>
<script>

/* eslint-disable no-new */
import { ZoomCenterTransition } from 'vue2-transitions'
import { mapGetters, mapActions } from 'vuex'

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

export default {
  components: {
    ZoomCenterTransition,
  },

  data () {
    return {
      isLoading: true,
      sidebarBackground: 'secondary' // vue|blue|orange|green|red|primary|secondary
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      darkMode: 'settings/darkMode',
    })
  },
  watch: {
    user: {
      handler (val) {
        if (!val) {
          this.$router.go()
        }
      }
    },
    darkMode: {
      handler () {
        this.setDarkMode()
      },
      immediate: true
    }
  },
  created () {
    this.reloadUser()
      .then((res) => {
        this.isLoading = false
      })
      .catch(() => {
        this.$router.go()
        this.isLoading = false
      })
  },
  methods: {
    ...mapActions({
      reloadUser: 'auth/reload'
    }),
    setDarkMode () {
      const docClasses = document.body.classList
      if (this.darkMode) {
        docClasses.remove('white-content')
      } else {
        docClasses.add('white-content')
      }
    },
  }
}
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
