import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import auth from '@/store/modules/auth'
import states from '@/store/modules/states'
import settings from '@/store/modules/settings'
import workings from '@/store/modules/workings'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    states,
    settings,
    workings
  }
})

export default store