const WorkingsList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Workings/List.vue')

export default [
  {
    path: 'workings',
    name: 'Lavorazioni',
    components: { default: WorkingsList }
  }
]
