import DashboardLayout from 'src/theme/layouts/DashboardLayout.vue'

import workingsRoutes from '@/routes/dashboard/workings.routes.js'

const dashboardPages = {
  path: '/',
  component: DashboardLayout,
  redirect: '/workings',
  name: 'Dashboard layout',
  meta: {
    auth: true
  },
  children: [
      ...workingsRoutes
  ]
}

export default dashboardPages
